import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'xpo-shell-header',
  templateUrl: './xpo-shell-header.component.html',
  styleUrls: ['./xpo-shell-header.component.scss'],
})
export class XpoShellHeaderComponent implements OnInit, OnDestroy {
  private unsubscriber: Unsubscriber = new Unsubscriber();
  disableAllNavigations$ = new BehaviorSubject<boolean>(false);
  navLinks = [
    {
      label: 'Pickup Requests',
      path: '/pur-list',
    },
  ];

  constructor(private changeDetectionRef: ChangeDetectorRef, private router: Router) {}

  ngOnInit(): void {
    this.subscribeToNavigationEndChanges();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  /**
   * Since some routes are lazy loaded, `routerLinkActive` does
   * not get updated to make the link look active, Need to manually
   * trigger change detection when a navigation end event triggers.
   * https://github.com/angular/angular/issues/19934
   */

  private subscribeToNavigationEndChanges(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(() => {
        if (this.router.url.includes('details') || this.router.url.includes('history')) {
          this.disableAllNavigations$.next(true);
        } else {
          this.disableAllNavigations$.next(false);
        }
        this.changeDetectionRef.markForCheck();
      });
  }
}
