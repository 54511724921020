import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XpoNotificationComponent } from './xpo-notification/xpo-notification.component';

@NgModule({
  declarations: [XpoNotificationComponent],
  imports: [CommonModule],
  exports: [XpoNotificationComponent],
})
export class XpoNotificationModule {}
