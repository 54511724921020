import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { XpoShellHeaderComponent } from './xpo-shell-header/xpo-shell-header.component';

@NgModule({
  declarations: [XpoShellHeaderComponent],
  imports: [CommonModule, RouterModule, MatTabsModule],
  exports: [XpoShellHeaderComponent],
})
export class XpoShellHeaderModule {}
