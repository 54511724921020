import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoErrorPage } from '@xpo-ltl/ngx-ltl-core/error-page';
import { AppRoutes } from '@xpo-ltl/ngx-pickup-request';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';

const routerDefinitions: Routes = [
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('../../projects/ngx-pickup-request/src/lib/edge-pickup-request.module').then(
  //       (m) => m.EdgePickupRequestModule
  //     ),
  //   canActivate: [XpoAuthenticationGuard, XpoLtlEnvironmentAndRoleGuard],
  // },
  {
    path: '',
    loadChildren: () => import('@xpo-ltl/ngx-pickup-request').then((m) => m.EdgePickupRequestModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.NOT_AUTHORIZED_PAGE,
    pathMatch: 'full',
    redirectTo: `/${AppRoutes.NOT_AUTHORIZED_PAGE}`,
  },
  {
    path: '503',
    component: XpoErrorPage,
    data: { statusCode: 503 },
  },
  {
    path: '403',
    component: XpoErrorPage,
    data: { statusCode: 403 },
  },
  {
    path: '**',
    redirectTo: `not-found`,
  },
  { path: '', pathMatch: 'full', redirectTo: `/${AppRoutes.PUR_LIST}` },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],

  // { relativeLinkResolution: 'legacy' }
  exports: [RouterModule],
})
export class AppRoutingModule {}
