import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-keyboard-shortcuts',
  templateUrl: './keyboard-shortcuts.component.html',
  styleUrls: ['./keyboard-shortcuts.component.scss'],
})
export class KeyboardShortcutsComponent {
  @Output() onClose: EventEmitter<boolean> = new EventEmitter(undefined);

  shortcuts = [
    [
      {
        name: 'Open Create Pickup Request Panel',
        keys: [
          {
            keyName: 'ALT',
          },
          {
            keyName: 'P',
          },
        ],
      },
      {
        name: 'Create Pickup',
        keys: [
          {
            keyName: 'ALT',
          },
          {
            isHex: true,
            keyName: '&#8629;',
          },
        ],
      },
    ],
    [
      {
        name: 'Update Pickup',
        keys: [
          {
            keyName: 'ALT',
          },
          {
            isHex: false,
            keyName: 'U',
          },
        ],
      },
      {
        name: 'Reschedule or Cancel Selected Pickups',
        keys: [
          {
            keyName: 'ALT',
          },
          {
            keyName: 'R',
          },
        ],
      },
    ],
    [
      {
        name: 'Cancel Pickup',
        keys: [
          {
            keyName: 'ALT',
          },
          {
            isHex: true,
            keyName: '&#8592;',
          },
        ],
      },
      {
        name: 'Jump through form section',
        keys: [
          {
            keyName: 'ALT',
          },
          {
            isHex: true,
            keyName: '&#8678;',
          },
          {
            isHex: true,
            keyName: '&#8680;',
          },
        ],
      },
    ],
    [
      {
        name: 'Jump to Pickup Date',
        keys: [
          {
            keyName: 'ALT',
          },
          {
            keyName: 'D',
          },
        ],
      },

      {
        name: 'Jump to empty Shipment line or add new',
        keys: [
          {
            keyName: 'ALT',
          },
          {
            keyName: 'S',
          },
        ],
      },
    ],
  ];

  onCloseClick() {
    this.onClose.emit(false);
  }
}
