<div class="xpo-footer">
  <div class="footer-left">
    <span>© {{ copyrightYear }} XPO.</span>
    <span class="build-version">Version - {{ buildVersion }}</span>
  </div>
  <div class="footer-right">
    <div class="footer-links">
      <a [href]="trainingResourcesLink" target="_blank">Training Resources</a>
      <a (click)="onShortcutsClick()">Keyboard Shortcuts</a>
      <a
        href="mailto:EdgePnDFeedback@xpo.com?subject=Feedback%20-%20Edge%20Pickup%20Request%20Application"
        target="_blank"
        >Feedback</a
      >
      <a class="footer-links__release-notes" (click)="handleReleaseNotesClick()" target="_blank"
        >Release Notes
        <div *ngIf="releaseNotesUpdateService.newReleaseNotesExist$ | async">!</div>
      </a>
    </div>
  </div>
</div>
