<nav class="ShellHeader" mat-tab-nav-bar>
  <a
    mat-tab-link
    *ngFor="let link of navLinks"
    [routerLink]="link.path"
    routerLinkActive
    [disabled]="disableAllNavigations$ | async"
    #rla="routerLinkActive"
    [active]="rla.isActive"
    [attr.data-test]="'appNavLink_' + link.label"
    [routerLinkActiveOptions]="{ exact: false }"
  >
    {{ link.label }}
  </a>
</nav>
