import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { AppMetadataApiService } from '@xpo-ltl-2.0/sdk-appmetadata';
import { CustomerApiService } from '@xpo-ltl-2.0/sdk-customer';
import { FreightFlowApiService as FreightFlowV2ApiService } from '@xpo-ltl-2.0/sdk-freightflow';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { PickupRequestV2ApiService } from '@xpo-ltl-2.0/sdk-pickuprequestv2';
import { RatingApiService, SdkRatingModule } from '@xpo-ltl-2.0/sdk-rating';
import { ShipmentApiService } from '@xpo-ltl-2.0/sdk-shipment';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { SupportedAppEnum, XpoMaintAppConfig, XpoMaintenanceModule } from '@xpo-ltl/maint-ui';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { XpoFiltersModule } from '@xpo-ltl/ngx-board/core';
import {
  XpoLtlReleaseNotesModule,
  XpoLtlRoleSwitcherModule,
  XpoLtlSicSwitcherModule,
} from '@xpo-ltl/ngx-ltl';
import { XpoAccountPopoverModule } from '@xpo-ltl/ngx-ltl-core/account-popover';
import { XpoAppSwitcherPopoverModule } from '@xpo-ltl/ngx-ltl-core/app-switcher-popover';
import { XpoApplicationSwitcherModule } from '@xpo-ltl/ngx-ltl-core/application-switcher';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoConfirmDialogModule } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { XpoFeedbackModule } from '@xpo-ltl/ngx-ltl-core/feedback';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoStatusIndicatorModule } from '@xpo-ltl/ngx-ltl-core/status-indicator';
import { XpoTabsModule } from '@xpo-ltl/ngx-ltl-core/tabs';
import { XpoUploadModule } from '@xpo-ltl/ngx-ltl-core/upload';
import { XpoLtlShipmentDetailModule } from '@xpo-ltl/ngx-ltl-shipment-details';
import { ReasonCodeModule } from '@xpo-ltl/ngx-pickup-request';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { CityOperationsApiService } from '@xpo-ltl/sdk-cityoperations';
import { SdkCustomerModule } from '@xpo-ltl/sdk-customer';
import { DockOperationsApiService } from '@xpo-ltl/sdk-dockoperations';
import { FreightFlowApiService } from '@xpo-ltl/sdk-freightflow';
import { GeoLocationApiService } from '@xpo-ltl/sdk-geolocation';
import { SdkInfrastructureModule } from '@xpo-ltl/sdk-infrastructure';
import { LocationApiService as LocationApiServiceV1, SdkLocationModule } from '@xpo-ltl/sdk-location';
import { SdkLoggingModule } from '@xpo-ltl/sdk-logging';
import { PickupRequestApiService } from '@xpo-ltl/sdk-pickuprequest';
import { ShipmentOdsApiService } from '@xpo-ltl/sdk-shipmentods';
import { ShipmentTrackingApiService } from '@xpo-ltl/sdk-shipmenttracking';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { XPO_AUTH_CONFIG, XpoAuthConfig } from '@xpo/ngx-auth';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KeyboardShortcutsComponent } from './keyboard-shortcuts/keyboard-shortcuts.component';
import { XpoFooterModule } from './xpo-footer/xpo-footer.module';
import { XpoNotificationModule } from './xpo-notification/xpo-notification.module';
import { XpoShellHeaderModule } from './xpo-shell-header/xpo-shell-header.module';
import { DecimalPipe } from '@angular/common';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [AppComponent, KeyboardShortcutsComponent],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    HttpClientModule,
    ConfigManagerModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatFormFieldModule,
    MatDialogModule,
    ReactiveFormsModule,
    DateRangePickerModule,
    XpoUploadModule,
    XpoAccountPopoverModule,
    XpoAppSwitcherPopoverModule,
    XpoLtlReleaseNotesModule,
    XpoLtlSicSwitcherModule,
    XpoLtlRoleSwitcherModule,
    XpoButtonModule,
    XpoCardModule,
    XpoShellModule,
    XpoFeedbackModule,
    XpoSnackBarModule,
    XpoStatusIndicatorModule,
    XpoFooterModule,
    XpoShellHeaderModule,
    SdkCustomerModule,
    SdkAppMetadataModule,
    SdkLocationModule,
    SdkUserPreferenceModule,
    SdkInfrastructureModule,
    SdkLoggingModule,
    SdkRatingModule,
    XpoMaintenanceModule.initialize(<XpoMaintAppConfig>{ appName: SupportedAppEnum.PUR }),
    // For SSO
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    XpoLtlAuthUiModule,
    // XpoForbiddenRoutingModule,
    // XpoErrorPageModule,
    XpoFiltersModule,
    XpoIconModule,
    XpoTabsModule,
    // XpoPageNotFoundRoutingModule,
    XpoApplicationSwitcherModule,
    XpoConfirmDialogModule,
    XpoLtlShipmentDetailModule,
    XpoNotificationModule,
    AppRoutingModule,
    ReasonCodeModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    DataApiService,
    LocationApiService,
    NotificationService,
    PickupRequestApiService,
    PickupRequestV2ApiService,
    CustomerApiService,
    FreightFlowApiService,
    FreightFlowV2ApiService,
    ShipmentApiService,
    AppMetadataApiService,
    CityOperationsApiService,
    DockOperationsApiService,
    ShipmentTrackingApiService,
    ShipmentOdsApiService,
    GeoLocationApiService,
    LocationApiServiceV1,
    RatingApiService,
    DecimalPipe,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
