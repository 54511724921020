<xpo-shell
  menuClosedOnDefault="true"
  class="app-Container purList-app-container"
  [routes]="routes"
  [drawerClosedByDefault]="true"
  [versionNumber]="build"
>
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>{{ title }}</xpo-header-sub-app-name>
  <xpo-header-items>
    <xpo-shell-header class="PurListShell__header"></xpo-shell-header>
  </xpo-header-items>
  <xpo-header-actions>
    <!--    TODO: help and privacy policy were not working. Need to update-->
    <xpo-auth-user-profile data-test="“appContainerUserProfile”">
      <xpo-auth-user-profile-links *ngIf="createReasonCodePermission">
        <ngx-pur-reason-code-settings></ngx-pur-reason-code-settings>
      </xpo-auth-user-profile-links>
    </xpo-auth-user-profile>
    <!--    TODO: Once after the sign out is working from xpo-account-popover remove the xpo-auth-user-profile-->
    <!--    <xpo-account-popover *ngIf="accountPopoverConfig" [config]="accountPopoverConfig"></xpo-account-popover>-->
    <xpo-auth-app-switcher [applications]="apps$ | async"></xpo-auth-app-switcher>
  </xpo-header-actions>
  <xpo-shell-sidebar-actions class="app-Container-sidebar-actions">
    <a
      data-test="appTrainingFeedbackLink"
      href="mailto:EdgePnDFeedback@xpo.com?subject=Feedback%20-%20Edge%20Pickup%20Request%20Application"
      target="_blank"
    >
      Feedback
    </a>
    <div>
      <a (click)="handleReleaseNotesClick()">Release Notes</a>
    </div>
  </xpo-shell-sidebar-actions>
  <router-outlet></router-outlet>
  <app-keyboard-shortcuts *ngIf="showShortcuts" (onClose)="showShortcuts = $event"></app-keyboard-shortcuts>
  <xpo-footer *ngIf="!showShortcuts" (onShortcut)="showShortcuts = $event"></xpo-footer>
</xpo-shell>
<xpo-notification></xpo-notification>
