<div class="shortcut-container">
  <div class="shortcut-header">
    <h2>Keyboard Shortcuts</h2>
    <mat-icon
      (click)="onCloseClick()"
      role="img"
      mat-icon-button=""
      class="mat-icon notranslate header-actions__close material-icons mat-icon-no-color"
      >close</mat-icon
    >
  </div>
  <div class="shortcut-body">
    <div class="shortcut-card" *ngFor="let arr of shortcuts">
      <div class="shortcut-row" *ngFor="let shortcut of arr">
        <div class="shortcut-name" [title]="shortcut?.name">{{ shortcut?.name }}</div>
        <div class="shortcut-keys">
          <div
            *ngFor="let key of shortcut.keys"
            [innerHTML]="key?.keyName"
            [ngClass]="{ 'shortcut-key': !key?.onlyText, 'hex-key': key?.isHex }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
