import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import {
  Unsubscriber,
  XpoLtlAppSwitcherService,
  XpoLtlLoggedInUserService,
  XpoLtlReleaseNotesService,
} from '@xpo-ltl/ngx-ltl';
import { XpoAccountPopoverConfig } from '@xpo-ltl/ngx-ltl-core/account-popover';
import { XpoAppSwitcherApplication } from '@xpo-ltl/ngx-ltl-core/app-switcher-popover';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

import {
  AccountUrls,
  ConfigManagerProperties,
  InteractionService,
  UserRoleService,
  UserService,
} from '@xpo-ltl/ngx-pickup-request';
import { User } from '@xpo-ltl/sdk-common';
import { XpoAuthenticationService } from '@xpo/ngx-auth';
import { invoke as _invoke, isEmpty as _isEmpty, toLower as _toLower } from 'lodash';
import { Observable, interval, of } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  retryWhen,
  skipWhile,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

declare var dtrum: any; // This is a dynatrace global Object

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private configManagerService: ConfigManagerService,
    private releaseNotesService: XpoLtlReleaseNotesService,
    private appSwitcherService: XpoLtlAppSwitcherService,
    private interactionService: InteractionService,
    private userService: UserService,
    private router: Router,
    private xpoSnackbar: XpoSnackBar,
    private userRoleService: UserRoleService,
    public loggedInUserService: XpoLtlLoggedInUserService,
    private xpoAuthenticationService: XpoAuthenticationService,
    private xpoLtlAuthService: XpoLtlAuthenticationService
  ) {
    (this.interactionService as any).globalSic = this.defaultSic;

    this.routes = [
      {
        label: 'Pickup Requests',
        path: '/pur-list',
      },
    ];

    /** Shell setup */
    this.title = 'Pickup Requests'; // configManagerService.getSetting<string>(ConfigManagerProperties.appName);
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);

    this.region = `${_toLower(configManagerService.getSetting<string>(ConfigManagerProperties.region))}`;

    this.xpoLtlAuthService.initAuthSetup$(this.region).subscribe(() => {});

    // when user is logged in finish initialization
    this.loggedInUserFunc();
  }
  title: string;
  build: string;
  region: string = '';
  apps$: Observable<XpoAppSwitcherApplication[]>;
  routes: XpoShellRoute[];
  defaultSic = 'UPO';
  createReasonCodePermission: boolean;
  readonly permissionToCreateReasonCode: string[] = [
    'rajat.dua@xpo.com',
    'vasudevan.damotharan@xpo.com',
    'shashank.srivastava@xpo.com',
    'rama.aluru@xpo.com',
    'seshagiri.jajam@xpo.com',
    'karthik.ampani@xpo.com',
    'suryamanoharan.venkat@xpo.com',
    'indhumathi.pandiaraj@xpo.com',
    'sumana.talakola@xpo.com',
    'michael.fadhl@xpo.com',
    'prathibha.tarikereshivamurthy@xpo.com',
    'lekha.satpute@xpo.com',
    'aparna.khandelwal@xpo.com',
    'tejashwini.doddipati@xpo.com',
    'neel.shah@xpo.com',
  ];
  accountPopoverConfig: XpoAccountPopoverConfig;
  private unsubscriber = new Unsubscriber();
  showShortcuts: boolean;

  private static getProfilePictureUrl(email: string): string {
    // return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
    return null;
  }

  PURUrlList = ['https://pur-i.dev-ltl-xpo.com', 'https://pur-4.dev-ltl-xpo.com', 'https://pur-5.dev-ltl-xpo.com', 'https://pur-s.dev-ltl-xpo.com', 'https://pur.ltl-xpo.com'];
  SHMUrlList = ['https://shplist-i.dev-ltl-xpo.com/pickups', 'https://shplist-4.dev-ltl-xpo.com/pickups', 'https://shplist-5.dev-ltl-xpo.com/pickups', 'https://shplist-s.dev-ltl-xpo.com/pickups', 'https://shplist.ltl-xpo.com/pickups'];

  ngOnInit() {
  }

  redirectToSHM() {
    const hostUrl = window.location.host;
    const timeoutDuration = 5000;
    const selectedPURIndex = this.PURUrlList.findIndex((url) => url.includes(hostUrl));
    if (selectedPURIndex > -1) {
      this.xpoSnackbar.open({
        message: 'You are being redirected to the new platform for Edge Pickup Request Application. Please save/bookmark the new URL for future uninterrupted access.',
        status: 'info',
        action: null,
        matConfig: {
          duration: timeoutDuration,
        },
      });
      setTimeout(() => {
        window.location.href = hostUrl.replace(hostUrl, this.SHMUrlList[selectedPURIndex]+this.router.url);
      }, timeoutDuration);
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  handleSicChanged(newSic: string): void {
    (this.interactionService as any).globalSic = newSic;
  }

  handleReleaseNotesClick(): void {
    this.releaseNotesService.showReleaseNotes().subscribe(() => {});
  }

  private loggedInUserFunc() {
    // NEED TO WAIT UNTIL AUTH SERVICE HAS COMPLETED SSO LOGIN BEFORE ANY ADDITIONAL CALLS ARE MADE
    this.xpoAuthenticationService
      .isLoggedIn$()
      .pipe(
        distinctUntilChanged(),
        switchMap((loggedIn: boolean) => {
          return !loggedIn
            ? of(undefined)
            : this.loggedInUserService
                .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
                .pipe(
                  retryWhen((errors) => errors.pipe(delay(1000), take(5))),
                  catchError((error) => {
                    console.log('loggedInUserFunc ERROR:', error);
                    return of(undefined);
                  })
                );
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((user: User) => {
        this.userService.user = user;
        this.userRoleService.user = user;
        if (user) {
          this.redirectToSHM();
          this.createReasonCodePermission = this.checkuseraddress(user);
          this.populateAccountPopover(user);
          this.populateAppSwitcher();
          this.setDynatraceUserIdentity(user);
        }
      });
  }

  private checkuseraddress(user: User): boolean {
    return this.permissionToCreateReasonCode.includes(user.emailAddress?.toLowerCase());
  }

  private populateAccountPopover(user: User): void {
    const fullName = `${user.givenName} ${user.lastName}`;

    this.accountPopoverConfig = {
      imageUri: AppComponent.getProfilePictureUrl(user.emailAddress),
      name: fullName,
      onSignOutCallback: (): void => {
        this.signOut();
      },
      links: [
        { title: 'My Account', url: AccountUrls.myAccount },
        { title: 'Help', url: AccountUrls.help },
        { title: 'Privacy Policy', url: AccountUrls.privacyPolicy },
      ],
    };
  }

  private signOut(): void {
    // Removing the local storage keys
    // TODO: signOut is not working with the latest seed app xpo-account-popover
    this.loggedInUserService.clear();
  }

  private populateAppSwitcher(): void {
    this.apps$ = this.appSwitcherService.getAppList();
  }

  private setDynatraceUserIdentity(user: User): void {
    const setUser = (): void =>
      _invoke(
        window['dtrum'],
        'identifyUser',
        !_isEmpty(user.emailAddress) ? user.emailAddress : !_isEmpty(user.userId) ? user.userId : 'PUR_USER'
      );

    if ((window['dtrum'] || {}).identifyUser) {
      setUser();
    } else {
      let retryCount: number = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !(window['dtrum'] || {}).identifyUser && retryCount <= 60),
          take(1)
        )
        .subscribe(() => {
          setUser();
        });
    }
  }
}
